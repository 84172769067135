export const PREFIX_PHONE_NUMBER = [
  { country: "Afghanistan", value: "+93", name: "+93", code: "AF", flag: "🇦🇫" },
  {
    country: "Åland Islands",
    value: "+358",
    name: "+358",
    code: "AX",
    flag: "🇦🇽",
  },
  { country: "Albania", value: "+355", name: "+355", code: "AL", flag: "🇦🇱" },
  { country: "Algeria", value: "+213", name: "+213", code: "DZ", flag: "🇩🇿" },
  {
    country: "American Samoa",
    value: "+1684",
    name: "+1684",
    code: "AS",
    flag: "🇺🇸",
  },
  { country: "Andorra", value: "+376", name: "+376", code: "AD", flag: "🇦🇩" },
  { country: "Angola", value: "+244", name: "+244", code: "AO", flag: "🇦🇴" },
  {
    country: "Anguilla",
    value: "+1264",
    name: "+1264",
    code: "AI",
    flag: "🇦🇮",
  },
  {
    country: "Antarctica",
    value: "+672",
    name: "+672",
    code: "AQ",
    flag: "🇦🇶",
  },
  {
    country: "Antigua and Barbuda",
    value: "+1268",
    name: "+1268",
    code: "AG",
    flag: "🇦🇬",
  },
  { country: "Argentina", value: "+54", name: "+54", code: "AR", flag: "🇦🇷" },
  { country: "Armenia", value: "+374", name: "+374", code: "AM", flag: "🇦🇲" },
  { country: "Aruba", value: "+297", name: "+297", code: "AW", flag: "🇦🇼" },
  { country: "Australia", value: "+61", name: "+61", code: "AU", flag: "🇦🇺" },
  { country: "Austria", value: "+43", name: "+43", code: "AT", flag: "🇦🇹" },
  {
    country: "Azerbaijan",
    value: "+994",
    name: "+994",
    code: "AZ",
    flag: "🇦🇿",
  },
  { country: "Bahamas", value: "+1242", name: "+1242", code: "BS", flag: "🇧🇸" },
  { country: "Bahrain", value: "+973", name: "+973", code: "BH", flag: "🇧🇸" },
  {
    country: "Bangladesh",
    value: "+880",
    name: "+880",
    code: "BD",
    flag: "🇧🇩",
  },
  {
    country: "Barbados",
    value: "+1246",
    name: "+1246",
    code: "BB",
    flag: "🇧🇧",
  },
  { country: "Belarus", value: "+375", name: "+375", code: "BY", flag: "🇧🇾" },
  { country: "Belgium", value: "+32", name: "+32", code: "BE", flag: "🇧🇪" },
  { country: "Belize", value: "+501", name: "+501", code: "BZ", flag: "🇧🇿" },
  { country: "Benin", value: "+229", name: "+229", code: "BJ", flag: "🇧🇯" },
  { country: "Bermuda", value: "+1441", name: "+1441", code: "BM", flag: "🇧🇲" },
  { country: "Bhutan", value: "+975", name: "+975", code: "BT", flag: "🇧🇹" },
  {
    country: "Bolivia, Plurinational State of bolivia",
    value: "+591",
    name: "+591",
    code: "BO",
    flag: "🇧🇴",
  },
  {
    country: "Bosnia and Herzegovina",
    value: "+387",
    name: "+387",
    code: "BA",
    flag: "🇧🇦",
  },
  { country: "Botswana", value: "+267", name: "+267", code: "BW", flag: "🇧🇼" },
  {
    country: "Bouvet Island",
    value: "+47",
    name: "+47",
    code: "BV",
    flag: "🏳",
  },
  { country: "Brazil", value: "+55", name: "+55", code: "BR", flag: "🇧🇷" },
  {
    country: "British Indian Ocean Territory",
    value: "+246",
    name: "+246",
    code: "IO",
    flag: "🇮🇴",
  },
  {
    country: "Brunei Darussalam",
    value: "+673",
    name: "+673",
    code: "BN",
    flag: "🇧🇳",
  },
  { country: "Bulgaria", value: "+359", name: "+359", code: "BG", flag: "🇧🇬" },
  {
    country: "Burkina Faso",
    value: "+226",
    name: "+226",
    code: "BF",
    flag: "🇧🇫",
  },
  { country: "Burundi", value: "+257", name: "+257", code: "BI", flag: "🇧🇮" },
  { country: "Cambodia", value: "+855", name: "+855", code: "KH", flag: "🇰🇭" },
  { country: "Cameroon", value: "+237", name: "+237", code: "CM", flag: "🇨🇲" },
  { country: "Canada", value: "+1", name: "+1", code: "CA", flag: "🇨🇦" },
  {
    country: "Cape Verde",
    value: "+238",
    name: "+238",
    code: "CV",
    flag: "🇨🇻",
  },
  {
    country: "Cayman Islands",
    value: "+ 345",
    name: "+ 345",
    code: "KY",
    flag: "🇰🇾",
  },
  {
    country: "Central African Republic",
    value: "+236",
    name: "+236",
    code: "CF",
    flag: "🇨🇫",
  },
  { country: "Chad", value: "+235", name: "+235", code: "TD", flag: "🇹🇩" },
  { country: "Chile", value: "+56", name: "+56", code: "CL", flag: "🇨🇱" },
  { country: "China", value: "+86", name: "+86", code: "CN", flag: "🇨🇳" },
  {
    country: "Christmas Island",
    value: "+61",
    name: "+61",
    code: "CX",
    flag: "🇨🇽",
  },
  {
    country: "Cocos (Keeling) Islands",
    value: "+61",
    name: "+61",
    code: "CC",
    flag: "🇨🇨",
  },
  { country: "Colombia", value: "+57", name: "+57", code: "CO", flag: "🇨🇴" },
  { country: "Comoros", value: "+269", name: "+269", code: "KM", flag: "🇰🇲" },
  { country: "Congo", value: "+242", name: "+242", code: "CG", flag: "🇨🇬" },
  {
    country: "Congo, The Democratic Republic of the Congo",
    value: "+243",
    name: "+243",
    code: "CD",
    flag: "🇨🇩",
  },
  {
    country: "Cook Islands",
    value: "+682",
    name: "+682",
    code: "CK",
    flag: "🇨🇰",
  },
  {
    country: "Costa Rica",
    value: "+506",
    name: "+506",
    code: "CR",
    flag: "🇨🇷",
  },
  {
    country: "Cote d'Ivoire",
    value: "+225",
    name: "+225",
    code: "CI",
    flag: "🇨🇮",
  },
  { country: "Croatia", value: "+385", name: "+385", code: "HR", flag: "🇭🇷" },
  { country: "Cuba", value: "+53", name: "+53", code: "CU", flag: "🇨🇺" },
  { country: "Cyprus", value: "+357", name: "+357", code: "CY", flag: "🇨🇾" },
  {
    country: "Czech Republic",
    value: "+420",
    name: "+420",
    code: "CZ",
    flag: "🇨🇿",
  },
  { country: "Denmark", value: "+45", name: "+45", code: "DK", flag: "🇩🇰" },
  { country: "Djibouti", value: "+253", name: "+253", code: "DJ", flag: "🇩🇯" },
  {
    country: "Dominica",
    value: "+1767",
    name: "+1767",
    code: "DM",
    flag: "🇩🇲",
  },
  {
    country: "Dominican Republic",
    value: "+1849",
    name: "+1849",
    code: "DO",
    flag: "🇨🇩",
  },
  { country: "Ecuador", value: "+593", name: "+593", code: "EC", flag: "🇪🇨" },
  { country: "Egypt", value: "+20", name: "+20", code: "EG", flag: "🇪🇬" },
  {
    country: "El Salvador",
    value: "+503",
    name: "+503",
    code: "SV",
    flag: "🇸🇻",
  },
  {
    country: "Equatorial Guinea",
    value: "+240",
    name: "+240",
    code: "GQ",
    flag: "🇬🇶",
  },
  { country: "Eritrea", value: "+291", name: "+291", code: "ER", flag: "🇪🇷" },
  { country: "Estonia", value: "+372", name: "+372", code: "EE", flag: "🇪🇪" },
  { country: "Ethiopia", value: "+251", name: "+251", code: "ET", flag: "🇪🇹" },
  {
    country: "Falkland Islands (Malvinas)",
    value: "+500",
    name: "+500",
    code: "FK",
    flag: "🇫🇰",
  },
  {
    country: "Faroe Islands",
    value: "+298",
    name: "+298",
    code: "FO",
    flag: "🇫🇴",
  },
  { country: "Fiji", value: "+679", name: "+679", code: "FJ", flag: "🇫🇯" },
  { country: "Finland", value: "+358", name: "+358", code: "FI", flag: "🇫🇮" },
  { country: "France", value: "+33", name: "+33", code: "FR", flag: "🇫🇷" },
  {
    country: "French Guiana",
    value: "+594",
    name: "+594",
    code: "GF",
    flag: "🇬🇫",
  },
  {
    country: "French Polynesia",
    value: "+689",
    name: "+689",
    code: "PF",
    flag: "🇵🇫",
  },
  {
    country: "French Southern Territories",
    value: "+262",
    name: "+262",
    code: "TF",
    flag: "🇹🇫",
  },
  { country: "Gabon", value: "+241", name: "+241", code: "GA", flag: "🇬🇦" },
  { country: "Gambia", value: "+220", name: "+220", code: "GM", flag: "🇬🇲" },
  { country: "Georgia", value: "+995", name: "+995", code: "GE", flag: "🇬🇪" },
  { country: "Germany", value: "+49", name: "+49", code: "DE", flag: "🇩🇪" },
  { country: "Ghana", value: "+233", name: "+233", code: "GH", flag: "🇬🇭" },
  { country: "Gibraltar", value: "+350", name: "+350", code: "GI", flag: "🇬🇮" },
  { country: "Greece", value: "+30", name: "+30", code: "GR", flag: "🇬🇷" },
  { country: "Greenland", value: "+299", name: "+299", code: "GL", flag: "🇬🇱" },
  { country: "Grenada", value: "+1473", name: "+1473", code: "GD", flag: "🇬🇩" },
  {
    country: "Guadeloupe",
    value: "+590",
    name: "+590",
    code: "GP",
    flag: "🇬🇵",
  },
  { country: "Guam", value: "+1671", name: "+1671", code: "GU", flag: "🇬🇺" },
  { country: "Guatemala", value: "+502", name: "+502", code: "GT", flag: "🇬🇹" },
  { country: "Guernsey", value: "+44", name: "+44", code: "GG", flag: "🇬🇬" },
  { country: "Guinea", value: "+224", name: "+224", code: "GN", flag: "🇬🇳" },
  {
    country: "Guinea-Bissau",
    value: "+245",
    name: "+245",
    code: "GW",
    flag: "🇬🇼",
  },
  { country: "Guyana", value: "+592", name: "+592", code: "GY", flag: "🇬🇾" },
  { country: "Haiti", value: "+509", name: "+509", code: "HT", flag: "🇭🇹" },
  {
    country: "Heard Island and Mcdonald Islands",
    value: "+0",
    name: "+0",
    code: "HM",
    flag: "🏳",
  },
  {
    country: "Holy See (Vatican City State)",
    value: "+379",
    name: "+379",
    code: "VA",
    flag: "🇻🇦",
  },
  { country: "Honduras", value: "+504", name: "+504", code: "HN", flag: "🇭🇳" },
  { country: "Hong Kong", value: "+852", name: "+852", code: "HK", flag: "🇭🇰" },
  { country: "Hungary", value: "+36", name: "+36", code: "HU", flag: "🇭🇺" },
  { country: "Iceland", value: "+354", name: "+354", code: "IS", flag: "🇮🇸" },
  { country: "India", value: "+91", name: "+91", code: "IN", flag: "🇮🇳" },
  { country: "Indonesia", value: "+62", name: "+62", code: "ID", flag: "🇮🇩" },
  {
    country: "Iran, Islamic Republic of Persian Gulf",
    value: "+98",
    name: "+98",
    code: "IR",
    flag: "🇮🇷",
  },
  { country: "Iraq", value: "+964", name: "+964", code: "IQ", flag: "🇮🇶" },
  { country: "Ireland", value: "+353", name: "+353", code: "IE", flag: "🇮🇪" },
  { country: "Isle of Man", value: "+44", name: "+44", code: "IM", flag: "🇮🇲" },
  { country: "Israel", value: "+972", name: "+972", code: "IL", flag: "🇮🇱" },
  { country: "Italy", value: "+39", name: "+39", code: "IT", flag: "🇮🇹" },
  { country: "Jamaica", value: "+1876", name: "+1876", code: "JM", flag: "🇯🇲" },
  { country: "Japan", value: "+81", name: "+81", code: "JP", flag: "🇯🇵" },
  { country: "Jersey", value: "+44", name: "+44", code: "JE", flag: "🇯🇪" },
  { country: "Jordan", value: "+962", name: "+962", code: "JO", flag: "🇯🇴" },
  { country: "Kazakhstan", value: "+7", name: "+7", code: "KZ", flag: "🇰🇿" },
  { country: "Kenya", value: "+254", name: "+254", code: "KE", flag: "🇰🇪" },
  { country: "Kiribati", value: "+686", name: "+686", code: "KI", flag: "🇰🇮" },
  {
    country: "Korea, Democratic People's Republic of Korea",
    value: "+850",
    name: "+850",
    code: "KP",
    flag: "🇰🇵",
  },
  {
    country: "Korea, Republic of South Korea",
    value: "+82",
    name: "+82",
    code: "KR",
    flag: "🇰🇷",
  },
  { country: "Kosovo", value: "+383", name: "+383", code: "XK", flag: "🇽🇰" },
  { country: "Kuwait", value: "+965", name: "+965", code: "KW", flag: "🇰🇼" },
  {
    country: "Kyrgyzstan",
    value: "+996",
    name: "+996",
    code: "KG",
    flag: "🇰🇬",
  },
  { country: "Laos", value: "+856", name: "+856", code: "LA", flag: "🇱🇦" },
  { country: "Latvia", value: "+371", name: "+371", code: "LV", flag: "🇱🇻" },
  { country: "Lebanon", value: "+961", name: "+961", code: "LB", flag: "🇱🇧" },
  { country: "Lesotho", value: "+266", name: "+266", code: "LS", flag: "🇱🇸" },
  { country: "Liberia", value: "+231", name: "+231", code: "LR", flag: "🇱🇷" },
  {
    country: "Libyan Arab Jamahiriya",
    value: "+218",
    name: "+218",
    code: "LY",
    flag: "🇱🇾",
  },
  {
    country: "Liechtenstein",
    value: "+423",
    name: "+423",
    code: "LI",
    flag: "🇱🇮",
  },
  { country: "Lithuania", value: "+370", name: "+370", code: "LT", flag: "🇱🇹" },
  {
    country: "Luxembourg",
    value: "+352",
    name: "+352",
    code: "LU",
    flag: "🇱🇺",
  },
  { country: "Macao", value: "+853", name: "+853", code: "MO", flag: "🇲🇴" },
  { country: "Macedonia", value: "+389", name: "+389", code: "MK", flag: "🇲🇰" },
  {
    country: "Madagascar",
    value: "+261",
    name: "+261",
    code: "MG",
    flag: "🇲🇬",
  },
  { country: "Malawi", value: "+265", name: "+265", code: "MW", flag: "🇲🇼" },
  { country: "Malaysia", value: "+60", name: "+60", code: "MY", flag: "🇲🇾" },
  { country: "Maldives", value: "+960", name: "+960", code: "MV", flag: "🇲🇻" },
  { country: "Mali", value: "+223", name: "+223", code: "ML", flag: "🇲🇱" },
  { country: "Malta", value: "+356", name: "+356", code: "MT", flag: "🇲🇹" },
  {
    country: "Marshall Islands",
    value: "+692",
    name: "+692",
    code: "MH",
    flag: "🇲🇭",
  },
  {
    country: "Martinique",
    value: "+596",
    name: "+596",
    code: "MQ",
    flag: "🇲🇶",
  },
  {
    country: "Mauritania",
    value: "+222",
    name: "+222",
    code: "MR",
    flag: "🇲🇷",
  },
  { country: "Mauritius", value: "+230", name: "+230", code: "MU", flag: "🇲🇺" },
  { country: "Mayotte", value: "+262", name: "+262", code: "YT", flag: "🇾🇹" },
  { country: "Mexico", value: "+52", name: "+52", code: "MX", flag: "🇲🇽" },
  {
    country: "Micronesia, Federated States of Micronesia",
    value: "+691",
    name: "+691",
    code: "FM",
    flag: "🇫🇲",
  },
  { country: "Moldova", value: "+373", name: "+373", code: "MD", flag: "🇲🇩" },
  { country: "Monaco", value: "+377", name: "+377", code: "MC", flag: "🇲🇨" },
  { country: "Mongolia", value: "+976", name: "+976", code: "MN", flag: "🇲🇳" },
  {
    country: "Montenegro",
    value: "+382",
    name: "+382",
    code: "ME",
    flag: "🇲🇪",
  },
  {
    country: "Montserrat",
    value: "+1664",
    name: "+1664",
    code: "MS",
    flag: "🇲🇸",
  },
  { country: "Morocco", value: "+212", name: "+212", code: "MA", flag: "🇲🇦" },
  {
    country: "Mozambique",
    value: "+258",
    name: "+258",
    code: "MZ",
    flag: "🇲🇿",
  },
  { country: "Myanmar", value: "+95", name: "+95", code: "MM", flag: "🇲🇲" },
  { country: "Namibia", value: "+264", name: "+264", code: "NA", flag: "🇳🇦" },
  { country: "Nauru", value: "+674", name: "+674", code: "NR", flag: "🇳🇷" },
  { country: "Nepal", value: "+977", name: "+977", code: "NP", flag: "🇳🇵" },
  { country: "Netherlands", value: "+31", name: "+31", code: "NL", flag: "🇳🇱" },
  {
    country: "Netherlands Antilles",
    value: "+599",
    name: "+599",
    code: "AN",
    flag: "🇳🇱",
  },
  {
    country: "New Caledonia",
    value: "+687",
    name: "+687",
    code: "NC",
    flag: "🇳🇨",
  },
  { country: "New Zealand", value: "+64", name: "+64", code: "NZ", flag: "🇳🇿" },
  { country: "Nicaragua", value: "+505", name: "+505", code: "NI", flag: "🇳🇮" },
  { country: "Niger", value: "+227", name: "+227", code: "NE", flag: "🇳🇪" },
  { country: "Nigeria", value: "+234", name: "+234", code: "NG", flag: "🇳🇬" },
  { country: "Niue", value: "+683", name: "+683", code: "NU", flag: "🇳🇺" },
  {
    country: "Norfolk Island",
    value: "+672",
    name: "+672",
    code: "NF",
    flag: "🇳🇫",
  },
  {
    country: "Northern Mariana Islands",
    value: "+1670",
    name: "+1670",
    code: "MP",
    flag: "🏳",
  },
  { country: "Norway", value: "+47", name: "+47", code: "NO", flag: "🇳🇴" },
  { country: "Oman", value: "+968", name: "+968", code: "OM", flag: "🇴🇲" },
  { country: "Pakistan", value: "+92", name: "+92", code: "PK", flag: "🇵🇰" },
  { country: "Palau", value: "+680", name: "+680", code: "PW", flag: "🇵🇼" },
  {
    country: "Palestinian Territory, Occupied",
    value: "+970",
    name: "+970",
    code: "PS",
    flag: "🇵🇸",
  },
  { country: "Panama", value: "+507", name: "+507", code: "PA", flag: "🇵🇦" },
  {
    country: "Papua New Guinea",
    value: "+675",
    name: "+675",
    code: "PG",
    flag: "🇵🇬",
  },
  { country: "Paraguay", value: "+595", name: "+595", code: "PY", flag: "🇵🇾" },
  { country: "Peru", value: "+51", name: "+51", code: "PE", flag: "🇵🇪" },
  { country: "Philippines", value: "+63", name: "+63", code: "PH", flag: "🇵🇭" },
  { country: "Pitcairn", value: "+64", name: "+64", code: "PN", flag: "🇵🇳" },
  { country: "Poland", value: "+48", name: "+48", code: "PL", flag: "🇵🇱" },
  { country: "Portugal", value: "+351", name: "+351", code: "PT", flag: "🇵🇹" },
  {
    country: "Puerto Rico",
    value: "+1939",
    name: "+1939",
    code: "PR",
    flag: "🇵🇷",
  },
  { country: "Qatar", value: "+974", name: "+974", code: "QA", flag: "🇶🇦" },
  { country: "Romania", value: "+40", name: "+40", code: "RO", flag: "🇷🇴" },
  { country: "Russia", value: "+7", name: "+7", code: "RU", flag: "🇷🇺" },
  { country: "Rwanda", value: "+250", name: "+250", code: "RW", flag: "🇷🇼" },
  { country: "Reunion", value: "+262", name: "+262", code: "RE", flag: "🇫🇷" },
  {
    country: "Saint Barthelemy",
    value: "+590",
    name: "+590",
    code: "BL",
    flag: "🇧🇱",
  },
  {
    country: "Saint Helena, Ascension and Tristan Da Cunha",
    value: "+290",
    name: "+290",
    code: "SH",
    flag: "🇸🇭",
  },
  {
    country: "Saint Kitts and Nevis",
    value: "+1869",
    name: "+1869",
    code: "KN",
    flag: "🇰🇳",
  },
  {
    country: "Saint Lucia",
    value: "+1758",
    name: "+1758",
    code: "LC",
    flag: "🇱🇨",
  },
  {
    country: "Saint Martin",
    value: "+590",
    name: "+590",
    code: "MF",
    flag: "🏳",
  },
  {
    country: "Saint Pierre and Miquelon",
    value: "+508",
    name: "+508",
    code: "PM",
    flag: "🇵🇲",
  },
  {
    country: "Saint Vincent and the Grenadines",
    value: "+1784",
    name: "+1784",
    code: "VC",
    flag: "🇻🇨",
  },
  { country: "Samoa", value: "+685", name: "+685", code: "WS", flag: "🇼🇸" },
  {
    country: "San Marino",
    value: "+378",
    name: "+378",
    code: "SM",
    flag: "🇸🇲",
  },
  {
    country: "Sao Tome and Principe",
    value: "+239",
    name: "+239",
    code: "ST",
    flag: "🇸🇹",
  },
  {
    country: "Saudi Arabia",
    value: "+966",
    name: "+966",
    code: "SA",
    flag: "🇸🇦",
  },
  { country: "Senegal", value: "+221", name: "+221", code: "SN", flag: "🇸🇳" },
  { country: "Serbia", value: "+381", name: "+381", code: "RS", flag: "🇷🇸" },
  {
    country: "Seychelles",
    value: "+248",
    name: "+248",
    code: "SC",
    flag: "🇸🇨",
  },
  {
    country: "Sierra Leone",
    value: "+232",
    name: "+232",
    code: "SL",
    flag: "🇸🇱",
  },
  { country: "Singapore", value: "+65", name: "+65", code: "SG", flag: "🇸🇬" },
  { country: "Slovakia", value: "+421", name: "+421", code: "SK", flag: "🇸🇰" },
  { country: "Slovenia", value: "+386", name: "+386", code: "SI", flag: "🇸🇮" },
  {
    country: "Solomon Islands",
    value: "+677",
    name: "+677",
    code: "SB",
    flag: "🇸🇧",
  },
  { country: "Somalia", value: "+252", name: "+252", code: "SO", flag: "🇸🇴" },
  {
    country: "South Africa",
    value: "+27",
    name: "+27",
    code: "ZA",
    flag: "🇿🇦",
  },
  {
    country: "South Sudan",
    value: "+211",
    name: "+211",
    code: "SS",
    flag: "🇸🇸",
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    value: "+500",
    name: "+500",
    code: "GS",
    flag: "🇬🇸",
  },
  { country: "Spain", value: "+34", name: "+34", code: "ES", flag: "🇪🇸" },
  { country: "Sri Lanka", value: "+94", name: "+94", code: "LK", flag: "🇱🇰" },
  { country: "Sudan", value: "+249", name: "+249", code: "SD", flag: "🇸🇩" },
  {
    country: "Suricountry",
    value: "+597",
    name: "+597",
    code: "SR",
    flag: "🇸🇷",
  },
  {
    country: "Svalbard and Jan Mayen",
    value: "+47",
    name: "+47",
    code: "SJ",
    flag: "🇩🇰",
  },
  { country: "Swaziland", value: "+268", name: "+268", code: "SZ", flag: "🇸🇿" },
  { country: "Sweden", value: "+46", name: "+46", code: "SE", flag: "🇸🇪" },
  { country: "Switzerland", value: "+41", name: "+41", code: "CH", flag: "🇨🇭" },
  {
    country: "Syrian Arab Republic",
    value: "+963",
    name: "+963",
    code: "SY",
    flag: "🇸🇾",
  },
  { country: "Taiwan", value: "+886", name: "+886", code: "TW", flag: "🇹🇼" },
  {
    country: "Tajikistan",
    value: "+992",
    name: "+992",
    code: "TJ",
    flag: "🇹🇯",
  },
  {
    country: "Tanzania, United Republic of Tanzania",
    value: "+255",
    name: "+255",
    code: "TZ",
    flag: "🇹🇿",
  },
  { country: "Thailand", value: "+66", name: "+66", code: "TH", flag: "🇹🇭" },
  {
    country: "Timor-Leste",
    value: "+670",
    name: "+670",
    code: "TL",
    flag: "🇹🇱",
  },
  { country: "Togo", value: "+228", name: "+228", code: "TG", flag: "🇹🇬" },
  { country: "Tokelau", value: "+690", name: "+690", code: "TK", flag: "🇹🇰" },
  { country: "Tonga", value: "+676", name: "+676", code: "TO", flag: "🇹🇴" },
  {
    country: "Trinidad and Tobago",
    value: "+1868",
    name: "+1868",
    code: "TT",
    flag: "🇹🇹",
  },
  { country: "Tunisia", value: "+216", name: "+216", code: "TN", flag: "🇹🇳" },
  { country: "Turkey", value: "+90", name: "+90", code: "TR", flag: "🇹🇷" },
  {
    country: "Turkmenistan",
    value: "+993",
    name: "+993",
    code: "TM",
    flag: "🇹🇲",
  },
  {
    country: "Turks and Caicos Islands",
    value: "+1649",
    name: "+1649",
    code: "TC",
    flag: "🇹🇨",
  },
  { country: "Tuvalu", value: "+688", name: "+688", code: "TV", flag: "🇹🇻" },
  { country: "Uganda", value: "+256", name: "+256", code: "UG", flag: "🇺🇬" },
  { country: "Ukraine", value: "+380", name: "+380", code: "UA", flag: "🇺🇦" },
  {
    country: "United Arab Emirates",
    value: "+971",
    name: "+971",
    code: "AE",
    flag: "🇦🇪",
  },
  {
    country: "United Kingdom",
    value: "+44",
    name: "+44",
    code: "GB",
    flag: "🇬🇧",
  },
  { country: "United States", value: "+1", name: "+1", code: "US", flag: "🇺🇸" },
  { country: "Uruguay", value: "+598", name: "+598", code: "UY", flag: "🇺🇾" },
  {
    country: "Uzbekistan",
    value: "+998",
    name: "+998",
    code: "UZ",
    flag: "🇺🇿",
  },
  { country: "Vanuatu", value: "+678", name: "+678", code: "VU", flag: "🇻🇺" },
  {
    country: "Venezuela, Bolivarian Republic of Venezuela",
    value: "+58",
    name: "+58",
    code: "VE",
    flag: "🇻🇪",
  },
  { country: "Vietnam", value: "+84", name: "+84", code: "VN", flag: "🇻🇳" },
  {
    country: "Virgin Islands, British",
    value: "+1284",
    name: "+1284",
    code: "VG",
    flag: "🇻🇬",
  },
  {
    country: "Virgin Islands, U.S.",
    value: "+1340",
    name: "+1340",
    code: "VI",
    flag: "🇻🇮",
  },
  {
    country: "Wallis and Futuna",
    value: "+681",
    name: "+681",
    code: "WF",
    flag: "🇼🇫",
  },
  { country: "Yemen", value: "+967", name: "+967", code: "YE", flag: "🇾🇪" },
  { country: "Zambia", value: "+260", name: "+260", code: "ZM", flag: "🇿🇲" },
  { country: "Zimbabwe", value: "+263", name: "+263", code: "ZW", flag: "🇿🇼" },
];
